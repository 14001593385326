import {
  FilterMusicOptions
} from '@/config/options';

export const InitDefaultValue = {
  data() {
    return {
      searchDefaultValue: {},
    };
  },

  created() {
    this.setDefaultLabels();
    this.initSearchLabelParams();
    this.initFilterPrams();
  },

  methods: {
    setDefaultLabels() {
      let query = this.$route.query;
      let defaultValue = this.searchDefaultValue;
      let label = {};

      query.labels && (label.label_ids = query.labels.split(','));

      defaultValue.sort = ''
      defaultValue.sort_type = FilterMusicOptions[0].value;

      defaultValue.label = label;

      this.searchDefaultValue = {
        ...defaultValue
      };
    },

    initSearchLabelParams(params) {
      let query = this.$route.query;
      let musicParams = this.musicParams;

      musicParams.label_ids = typeof query.labels === 'string' ? query.labels.split(',') : '';

      this.musicParams = Object.assign({}, musicParams, params ? params : {});
      this.musicNumParams = Object.assign({}, this.musicNumParams, musicParams);
    },

    initFilterPrams() {
      let musicParams = this.musicParams;

      musicParams.sort = ''
      musicParams.sort_type = FilterMusicOptions[0].value;

      this.musicParams = Object.assign({}, musicParams);
    },
  }
}