<template>
  <div class="music-table-box">
    <ng-table
      :columns="columns"
      :request="request"
      :params="params"
      :reload="reload"
      :rowKey="(scope) => scope.music_id"
      :isMore="isMore"
      :pagination="pagination"
      :transformData="onTransformData"
      :changeData="changeTableData"
      :data-list="dataSource"
      :isImmediate="isImmediate"
      @dataChange="onDataChange"
      keyId="music_id"
      placement="bottom"
      ref="ngTable" 
    >
      <template slot="play" slot-scope="text, scope">
        <div class="music-play-icon-box">
          <music-play-icon :scope="scope" :list="tableList"></music-play-icon>
        </div>
      </template>

      <template slot="name" slot-scope="text, scope">
        <div class="ellipsis" style="max-width: 180px;">
          <router-link
            class="font-bold font-color-base m-bottom-xs"
            :to="`/musicDetail/${scope.music_id}`"
            :title="scope.name"
          >{{scope.name}}</router-link>
          <router-link
            class="font-color-base block"
            :to="`/supplierInfo/${scope.suplr_id || '-1'}`"
          >{{scope.suplr_name}}</router-link>
        </div>
      </template>

      <template :slot="rsColumnsKeys" slot-scope="text, scope">
        <icon-font class="font-md font-color-sub" v-if="scope[rsColumnsKeys]" type="icontube"></icon-font>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <music-operation
          :videoInfo="scope"
          @closeDrawer="onCloseDrawer"
          @success="$emit('success')"
        ></music-operation>
      </template>
    </ng-table>
  </div>
</template>

<script>
  import NgTable from '@/components/NgTable.vue';
  import MusicOperation from '@/pages/components/music/MusicOperation.vue';
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'
  import {UpdateMusicList} from '@/mixins/music';

  // import {RenShenCateId} from '@/config/config'

  import * as $col from '@/config/columns'

  const columns = (middleColumns) => {
    return [
      $col.commonKey('', 'play', {isSlot: true, width: '40px', className: 'first-table-cell'}),
      $col.colIdFunc((item, index) => `${index + 1}`.padStart(2, '0'), '', '40px'),
      $col.commonKey('作品/音乐人', 'name', {isSlot: true}),
      // $col.commonKey('风格', 'style', {onlyPc: true}),
      // $col.commonKey('情绪', 'qx', {onlyPc: true}),
      // $col.commonKey('人声', 'rs', {onlyPc: true, isSlot: true}),
      ...middleColumns,
      $col.commonKey('BPM', 'bpm', {onlyPc: true, width: '80px'}),
      $col.commonKey('时长', 'duration', {onlyPc: true, width: '80px'}),
      $col.commonKey('', 'operation', {isSlot: true, width: '230px'})
    ]
  }

  const rsColumnsKeys = 'rensheng';
  const LabelSplitKey = '、';
  const KeyBySplitLableIdAndName = '_';

  export default {
    data() {
      return {
        columns: columns([]),
        labels: [],
        rsColumnsKeys,
        changeTableData: [],
        tableList: [],
      }
    },

    props: {
      isMore: {
        type: Boolean,
        default: true
      },
      request: Function,
      params: Object,
      dataSource: Array,
      reload: Boolean,
      isImmediate: {
        type: Boolean,
        default: true
      },
      // songListId: String,
      transformData: Function,
      pagination: {},
    },

    watch: {
      changeData: {
        handler(newVal) {
          if (newVal && newVal.length > 0) {
            this.changeTableData = newVal;
          }
        }
      },
    },

    components: {
      NgTable,
      MusicOperation,
      MusicPlayIcon
    },

    created() {
      this.getLabelsData();
    },

    mixins: [UpdateMusicList],

    methods: {
      getLabelsData() {
        let params = {type: this.$val.ShareAndCollectType.music};

        this.$axios.CommonLabels(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let labels = data.data ? data.data.list : [];

            this.columns = columns(labels.map(item => {
              let widthDir = {};
              let name = item.name, key = item.cate_id;

              // 如果为声音那么录入固定的key值
              if (~name.indexOf('声音')) {
                name = '人声';
                key = rsColumnsKeys;
                widthDir.width = '60px';

                return $col.commonKey(name, key, {onlyPc: true, isSlot: true, ...widthDir})
              }

              let customRender = (text) => {
                let textArr = text.split(LabelSplitKey);

                return textArr.map((item, index) => {
                  let nameArr = item.split(KeyBySplitLableIdAndName);
                  let name = text, label_id = '';

                  if (nameArr && nameArr.length > 0) {
                    name = nameArr[0];
                    label_id = nameArr[1] || '';
                  }

                  let split = index < textArr.length - 1 ? LabelSplitKey : '';

                  return (<router-link class="font-color-base" to={`/allMusic?labels=${label_id}`}>{name}{split}</router-link>)
                })
              };

              return $col.commonKey(name, key, {onlyPc: true, isSlot: true, ...widthDir, customRender})
            }))
          }
        })
      },

      // handleScopeInfo(scope) {
      //   let extraObj = this.songListId ? {sid: this.songListId, isSongList: true} : {};

      //   return Object.assign({}, scope, extraObj)
      // },

      onDataChange(data) {
        this.tableList = data;
        // this.$store.commit('changeVideoList', data || []);
      },

      onTransformData(data) {
        data = data.map(item => {
          let labels = item.labels || [];

          return labels.reduce((prev, next) => {
            let allName = this.getAllName(next.child) || [];
            let name = next.name;

            // 对声音类别中的人声特殊处理
            if (!~name.indexOf('声音')) {
              prev[next.cate_id] = allName.join(LabelSplitKey);
            } else {
              prev[rsColumnsKeys] = allName.filter(item => ~item.indexOf('人声')).join(LabelSplitKey);
            }

            return prev;
          }, item);
        });

        return typeof this.transformData === 'function' ? this.transformData(data) : data;
      },
      onCloseDrawer() {
        this.$refs.ngTable && this.$refs.ngTable.onCloseDrawer();
      },

      getAllName(data) {
        return one(data, []);

        function one(data, arr) {
          return data.reduce((prev, next) => {
            if (next.child && next.child.length > 0) {
              let childName = one(next.child, prev);

              prev = prev.concat(childName);
            }

            prev.push(`${next.name}${KeyBySplitLableIdAndName}${next.label_id}`);

            return prev;
          }, arr)
        }
      }

      // getAllName(data) {
      //   return one(data, []);

      //   function one(data, arr) {
      //     return data.reduce((prev, next) => {
      //       if (next.child && next.child.length > 0) {
      //         let childName = one(next.child, prev);

      //         prev = prev.concat(childName);
      //       }

      //       prev.push(next.name);

      //       return prev;
      //     }, arr)
      //   }
      // }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    @media screen and (max-width: $screen-width-lg) {
      &.music-table-box {
        .ant-table-body colgroup col,
        .ant-table-thead th,
        .ant-table-body td {
          &:nth-child(4),
          &:nth-child(5) {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $screen-width-xl) {
      &.music-table-box {
        .ant-table-body colgroup col,
        .ant-table-thead th,
        .ant-table-body td {
          &:nth-child(6),
          &:nth-child(7) {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $screen-width-md) {
      &.music-table-box {
        .ant-table-thead th,
        .ant-table-body td {
          &:last-child {
            display: table-cell;
          }
        }

        .ant-table-body colgroup col {
          &:last-child {
            display: table-column;
          }
        }
      }
    }

    &.music-table-box {
      .ant-table-body {
        td,
        th {
          transition: all linear 0.2s;
        }
      }
    }
  }

  /deep/ {
    .first-table-cell {
      padding-right: 0 !important;

      & + td {
        padding-right: 0 !important;
      }
    }
  }
</style>
