<template>
  <div class="search-page-wrap container">
    <!-- 歌单 -->
    <div v-if='false' class="genre-wrap m-bottom-60">
      <div class="look-more-wrap flex cen-space m-bottom-md">
        <page-title-icon title="歌单"></page-title-icon>
      </div>

      <ng-load-page
        class="m-top-base song-list-box"
        keyId="playlist_id"
        :request="$axios.Music_Play_List"
        :params="listParams"
        :changeData="changeItem"
        :reload.sync="reloadTable"
        :wrapperStyle="{minHeight: '200px'}"
      >
        <template v-slot:default="{data}">
          <a-row :gutter="20">
            <a-col
              v-for="item in data"
              class="m-bottom-md"
              :xs="12"
              :sm="12"
              :md="4"
              :key="item.playlist_id"
            >
              <song-list-item :item="item" isShowCollect @update="updateListItem"></song-list-item>
            </a-col>
          </a-row>
        </template>
      </ng-load-page>
    </div>

    <!-- 音乐 -->
    <div class="result-music-list">
      <div v-if="false" class="tit flex cen-space m-bottom-md">
        <page-title-icon title="音乐"></page-title-icon>
      </div>

      <filter-music
        :defaultValue="searchDefaultValue"
        :musicNumParams="musicNumParams"
        @change="onChangeParams"
        @labelsChange="onChangeLabels"
      ></filter-music>

      <music-table :request="$axios.Music_List" :params="musicParams" is-more :pagination="false"></music-table>
    </div>
  </div>
</template>

<script>
  import PageTitleIcon from "@/pages/components/common/PageTitleIcon.vue";
  import SongListItem from '@/pages/components/songList/SongListItem.vue'
  import MusicTable from '@/pages/components/music/MusicTable.vue'
  import NgLoadPage from '@/components/NgLoadPage.vue'
  import FilterMusic from '@/pages/components/music/filter/FilterMusic.vue'

  import {InitDefaultValue} from '@/pages/components/music/filter/filterMusicMixins'

  export default {
    data() {
      return {
        musicParams: {},
        reloadTable: false,
        listParams: {},
        changeItem: [],
        initSearchLabel: {},
        musicNumParams: {}
      };
    },

    watch: {
      $route(to) {
        let query = to.query;
        let keyword = query.q || '';

        this.setSearchParams(decodeURI(keyword));
      }
    },

    mixins: [InitDefaultValue],

    created() {
      let query = this.$route.query;
      let keyword = query.q || '';

      this.setSearchParams(decodeURI(keyword));
    },

    components: {
      SongListItem,
      NgLoadPage,
      PageTitleIcon,
      MusicTable,
      FilterMusic,
    },

    methods: {
      setSearchParams(keyword) {
        this.musicParams = Object.assign({}, this.musicParams, {keyword});
        this.listParams = Object.assign({}, this.listParams, {keyword});
        this.musicNumParams = Object.assign({}, this.musicNumParams, {keyword});
      },

      updateListItem(scope) {
        this.changeItem = [scope];

        this.$nextTick(() => {
          this.changeItem = [];
        })
      },

      onChangeParams(params) {
        this.musicParams = Object.assign({}, this.musicParams, params);
        // this.musicNumParams = Object.assign({}, this.musicNumParams, this.musicParams);
      },

      onChangeLabels(params) {
        this.musicNumParams = Object.assign({}, this.musicNumParams, params);
      }
    },
  };
</script>
<style lang="scss">
  .search-page-wrap {
    margin-top: 60px;
  }
</style>