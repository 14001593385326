<template>
  <a-row class="music-opt">
    <a-col v-if="showShare" class="music-opt-item drawer-list-item" :xs="24" :sm="24" :md="{span: 4}">
      <div class="flex cen-start icon-item-box hidden-sm" @click="goShareMusic(videoInfo)">
        <a-tooltip title="分享">
          <icon-font class="iconfont" type="iconshare"></icon-font>
        </a-tooltip>
      </div>
      <div class="flex cen-start icon-item-box hidden-md" @click="goShareMusic(videoInfo)">
        <icon-font class="iconfont" type="iconshare"></icon-font>
        <span class="text">分享</span>
      </div>
    </a-col>

    <a-col  v-if="showSimilar" class="music-opt-item drawer-list-item" :xs="24" :sm="24" :md="{span: 4, offset: showHistory?0:1}">
      <div class="flex cen-start icon-item-box hidden-sm" @click="() => {goSimilar(videoInfo)}">
        <a-tooltip title="相似">
          <icon-font class="iconfont" type="iconsimilar"></icon-font>
        </a-tooltip>
      </div>
      <div class="flex cen-start icon-item-box hidden-md" @click="() => {goSimilar(videoInfo)}">
        <icon-font class="iconfont" type="iconsimilar"></icon-font>
        <span class="text">相似</span>
      </div>
    </a-col>

    <a-col class="music-opt-item drawer-list-item" :xs="24" :sm="24" :md="{span: !showShare?5:4, offset: showHistory?0:1}">
      <div
        class="flex cen-start icon-item-box hidden-sm"
        @click="() => {goCollectMusic(videoInfo)}"
      >
        <a-tooltip title="喜欢">
          <icon-font v-if="videoInfo.is_collected == '0'" class="iconfont" type="iconheart-empty"></icon-font>
          <icon-font v-else class="iconfont font-color-primary" type="iconheart"></icon-font>
        </a-tooltip>
      </div>
      <div
        class="flex cen-start icon-item-box hidden-md"
        @click="() => {goCollectMusic(videoInfo)}"
      >
        <icon-font v-if="videoInfo.is_collected == '0'" class="iconfont" type="iconheart-empty"></icon-font>
        <icon-font v-else class="iconfont font-color-primary" type="iconheart"></icon-font>
        <span class="text">喜欢</span>
      </div>
    </a-col>

    <a-col class="music-opt-item drawer-list-item" :xs="24" :sm="24" :md="{span: !showShare?5:4, offset: showHistory?0:1}">
      <div
        class="flex cen-start icon-item-box hidden-sm"
        @click="() => {goAddMusicToShopCart(videoInfo)}"
      >
        <a-tooltip title="加购">
          <icon-font class="iconfont" type="iconcart-empty"></icon-font>
        </a-tooltip>
      </div>
      <div
        class="flex cen-start icon-item-box hidden-md"
        @click="() => {goAddMusicToShopCart(videoInfo)}"
      >
        <icon-font class="iconfont" type="iconcart-empty"></icon-font>
        <span class="text">加购</span>
      </div>
    </a-col>

    <a-col class="music-opt-item drawer-list-item" :xs="24" :sm="24" :md="{span: 4, offset: showHistory?0:1}">
      <div
        class="flex cen-start icon-item-box hidden-sm"
        @click="() => {goDownloadMusic(videoInfo)}"
      >
        <a-tooltip title="下载">
          <icon-font class="iconfont" type="icondownload"></icon-font>
        </a-tooltip>
      </div>
      <div
        class="flex cen-start icon-item-box hidden-md"
        @click="() => {goDownloadMusic(videoInfo)}"
      >
        <icon-font class="iconfont" type="icondownload"></icon-font>
        <span class="text">下载</span>
      </div>
    </a-col>

    <a-col v-if="showHistory" class="music-opt-item drawer-list-item " :xs="24" :sm="24" :md="{span: 4, offset: 0}">
      <div
        class="flex cen-start icon-item-box hidden-sm"
        @click="() => {openHistoryMusic(videoInfo)}"
      >
       <a-tooltip title="历史">
          <icon-font class="iconfont" type="iconwait-empty"></icon-font>
       </a-tooltip>
      </div>
      <div
        class="flex cen-start icon-item-box hidden-md"
        @click="() => {openHistoryMusic(videoInfo)}"
      >
        <icon-font class="iconfont" type="icondownload"></icon-font>
        <span class="text">历史</span>
      
      </div>
    </a-col>
  </a-row>
</template>

<script>
  import {Tooltip} from 'ant-design-vue'
  import musicHandle from '@/mixins/musicHandle'
  import { mapState , mapMutations } from "vuex";

  export default {
    data() {
      return {

      }
    },

    mixins: [musicHandle],

    props: {
      videoInfo: {
        type: Object,
        default() {
          return {}
        }
      },
      showText: Boolean,
      showHistory:{
        type: Boolean,
        default: false
      },
      showSimilar:{
        type: Boolean,
        default: true
      },
      showShare:{
        type: Boolean,
        default: true
      },
      type: {
        type: String,
        default: 'music'
      }, // 音乐、歌单
    },

    watch: {
      type: {
        handler(newVal) {
          this.goodType = newVal;
        },
        immediate: true
      }
    },

    components: {
      ATooltip: Tooltip,
      //ADropdown: Dropdown,
      //AMenu: Menu,
      //AMenuItem: Menu.Item,
    },
    computed: {
    ...mapState(["historyMusicDialog"]),
   
  },
    methods: {
    ...mapMutations(["changeHistoryMusicDialog"]),

      openHistoryMusic(){
         if (!this.$root.checkLoginStatus()) return;
        this.changeHistoryMusicDialog(!this.historyMusicDialog)
        setTimeout(() =>{
          document.body.style ={
            overflow: 'auto',
            width: '100%'
          }
        },10)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .music-opt {
    .music-opt-item {
      cursor: pointer;
      
      .iconfont {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }

      .text {
        margin-left: 12px;
      }
    }
  }
</style>
