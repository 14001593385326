<template>
  <div class="filter-labels w-100">
    <div class="filter-top m-bottom-base">
      <a-radio-group size="large" v-model="curCate" @change="onChangeCate">
        <a-radio-button
          v-for="item in labels"
          size="large"
          class="form-button m-right-sm m-bottom-xs"
          :key="item.cate_id"
          :value="item.cate_id"
        >
          <span class="m-right-sm">{{item.name}}</span>
          <icon-font type="iconfilter"></icon-font>
        </a-radio-button>
        <a-radio-button
          v-for="item in ExtraDir"
          class="form-button m-right-sm m-bottom-sm"
          size="large"
          :value="item.key"
          :key="item.key"
        >
          <span class="m-right-sm">{{item.name}}</span>
          <icon-font type="iconfilter"></icon-font>
        </a-radio-button>
      </a-radio-group>

      <slot name="top"></slot>
    </div>

    <div class="filter-bottom overflow-hidden">
      <template v-if="curCate !== ExtraKeyDir.duration && curCate !== ExtraKeyDir.bpm">
        <!-- class="form-button m-right-sm m-bottom-xs" -->
        <a-button
          v-for="item in secondLabels"
          type="sub"
          class="form-button tag-button-class"
          :key="item.label_id"
          :disabled="!!~selecteLabelIds.indexOf(item.label_id)"
          @click="onClickLables(item)"
        >
          <span>{{item.name}} {{musicNumDir && musicNumDir[item.label_id] ? musicNumDir[item.label_id] : ''}}</span>
        </a-button>
      </template>

      <a-row v-else-if="curCate === ExtraKeyDir.duration" class="filter-middle">
        <a-col class="flex cen-space filter-middle-item" :xs="24" :sm="24" :md="12">
          <span class="m-right-xs label">时长</span>
          <a-slider
            class="flex-1"
            v-model="durationSliderValue"
            :default-value="durationRange"
            :min="durationRange[0]"
            :max="durationRange[1]"
            range
            @afterChange="onAfterChangeDuration"
            :tipFormatter="(value) => formatTimeBySecond(value)"
            :key="durationCompIndex"
          />
        </a-col>
      </a-row>

      <a-row v-else class="filter-middle">
        <a-col class="flex cen-space filter-middle-item" :xs="24" :sm="24" :md="12">
          <span class="m-right-xs label">BPM</span>
          <a-slider
            class="flex-1"
            v-model="bpmSliderValue"
            :default-value="bpmRange"
            :min="bpmRange[0]"
            :max="bpmRange[1]"
            range
            @afterChange="onAfterChangeBpm"
            :key="bpmCompIndex"
          />
        </a-col>
      </a-row>
    </div>

    <div v-if="hasFooter" class="filter-footer">
      <a-divider></a-divider>

      <a-tag
        v-for="item in selecteLabels"
        :key="item.label_id"
        @close="onCloseTag(item)"
        v-bind="tagAttr"
      >{{ item.name }}</a-tag>

      <a-tag
        v-if="bpmSlider && bpmSlider.length > 0"
        @close="onCloseBpm"
        v-bind="tagAttr"
      >{{getBpmText()}}</a-tag>

      <a-tag
        v-if="durationSlider && durationSlider.length > 0"
        @close="onCloseDuration"
        v-bind="tagAttr"
      >{{getDurationText()}}</a-tag>

      <a-button type="link" @click="onClearSelectTag">清空筛选</a-button>
    </div>
  </div>
</template>

<script>
  import {Tag} from 'ant-design-vue';
  import {darken} from '@/utils/function'
  import {formatTimeBySecond} from '@/utils/function'

  const sliderRange = {
    duration: [0, 5],
    bpm: [40, 280]
  }

  const ExtraKeyDir = {
    bpm: 'bpm',
    duration: 'duration'
  }

  const ExtraDir = [
    {
      name: 'BPM',
      key: ExtraKeyDir.bpm
    },
    {
      name: '时长',
      key: ExtraKeyDir.duration
    }
  ]

  export default {
    data() {
      return {
        labels: [],

        secondLabels: [],

        ExtraDir,
        ExtraKeyDir,

        sliderRange,

        // musicNumDir: {},

        curCate: '',

        durationRange: this.$config.MusicSearchDurationRange,
        bpmRange: this.$config.MusicSearchBpmRange,

        selecteLabels: [],
        selecteLabelIds: [],

        durationSlider: [],
        bpmSlider: [],

        durationSliderValue: this.durationRange,
        bpmSliderValue: this.bpmRange,

        durationCompIndex: 0,
        bpmCompIndex: 0,

        tagAttr: {
          size: 'large',
          closable: true,
          class: 'm-right-sm m-bottom-xs',
          color: '#FEF4EB',
          style: {color: darken('#FEF4EB'), borderColor: darken('#FEF4EB')},
        }
      }
    },

    props: {
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      musicNumDir: {
        type: [Object, Array],
        default() {
          return {}
        }
      }
    },

    components: {
      ATag: Tag
    },

    created() {
      this.allLabelsArr = [];

      this.getLabels();
      this.initDurationSlider();
      this.initBpmSlider();
    },

    computed: {
      hasFooter() {
        let sl = this.selecteLabels;
        let ds = this.durationSlider;
        let bs = this.bpmSlider;

        return (sl && sl.length > 0) || (ds && ds.length > 0) || (bs && bs.length > 0);
      }
    },

    watch: {
      defaultValue: {
        handler(newVal) {
          if (!newVal || Object.keys(newVal).length === 0) newVal = {};

          let {duration = [], bpm = [], label_ids = []} = newVal || {};

          if (duration && duration.length !== 0) {
            this.durationSliderValue = duration;
            this.durationSlider = duration
          }

          if (bpm && bpm.length !== 0) {
            this.bpmSliderValue = bpm;
            this.bpmSlider = bpm;
          }

          if (label_ids) {
            if (this.allLabelsArr && this.allLabelsArr.length > 0) {
              this.setDefaultSelectLabels(label_ids);
            } else {
              this.selecteLabelsCopy = label_ids;
            }
          }
        },
        immediate: true
      },
    },

    methods: {
      darken,
      formatTimeBySecond,
      getLabels() {
        this.$axios.CommonLabels({type: 1}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data ? data.data.list : [];

            this.labels = list;

            this.cateDir = list.reduce((prev, next) => {
              next.cate_id && (prev[next.cate_id] = next.child || []);

              return prev;
            }, {})

            this.allLabelsArr = this.getAllLabels(list);

            let defaultCateId = list[0] ? list[0].cate_id : '';

            this.defaultCateId = defaultCateId;

            // 如果有默认标签初始化选中标签
            if (this.selecteLabelsCopy && this.selecteLabelsCopy.length > 0) {
              let label_ids = this.selecteLabelsCopy;

              this.setDefaultSelectLabels(label_ids);

              // 如果没有找到搜索标签，默认只展开
              if (!this.secondLabels) {
                this.curCate = defaultCateId
                this.secondLabels = this.cateDir[defaultCateId];
              }

              this.selecteLabelsCopy = [];
            }

            // 初始化类别标签
            else if ((!this.defaultValue || Object.keys(this.defaultValue).length === 0) && list[0]) {
              this.curCate = defaultCateId;
              this.secondLabels = this.cateDir[defaultCateId];
            }
          }
        })
      },

      setDefaultSelectLabels(label_ids) {
        let labels = this.allLabelsArr.filter(item => !!~label_ids.indexOf(item.label_id)) || [];

        this.selecteLabels = labels || [];
        this.curCate = labels.length > 0 ? (labels[labels.length - 1].cate_id || '') : this.defaultCateId;
        this.secondLabels = this.cateDir[this.curCate];

        this.setSelectedLabelIds();
      },

      getBpmText() {
        return `BPM ${this.bpmSlider.join('-')}`
      },

      getDurationText() {
        return `${this.durationSlider.map(item => formatTimeBySecond(item)).join('-')}`
      },

      setSelectedLabelIds() {
        this.selecteLabelIds = this.selecteLabels.map(item => item.label_id);
      },

      toggleValueChange() {
        let values = {
          label_ids: this.selecteLabelIds,
          bpm: this.bpmSlider,
          duration: this.durationSlider
        }

        this.$emit('change', values);
      },

      initBpmSlider() {
        this.bpmSliderValue = this.bpmRange;
      },

      initDurationSlider() {
        this.durationSliderValue = this.durationRange;
      },

      onAfterChangeDuration(value) {
        this.durationSlider = value;
        this.toggleValueChange();
      },

      onAfterChangeBpm(value) {
        this.bpmSlider = value;
        this.toggleValueChange();
      },

      onChangeCate(event) {
        let value = event.target.value;
        let cateDir = this.cateDir;
        let curAllLabels = cateDir[value];

        this.secondLabels = curAllLabels;
      },

      onClickLables(item) {
        this.selecteLabels.push(item);
        this.setSelectedLabelIds();
        this.toggleValueChange();
      },

      onClearSelectTag() {
        this.durationSlider = [];
        this.bpmSlider = [];
        this.selecteLabels = [];
        this.setSelectedLabelIds();
        this.initDurationSlider();
        this.initBpmSlider();
        // this.$root.$goSearch({labels: ''})
        this.toggleValueChange();
      },

      onCloseTag(nitem) {
        this.selecteLabels = this.selecteLabels.filter(item => item.label_id !== nitem.label_id);
        this.setSelectedLabelIds();
        this.toggleValueChange();
      },

      onCloseBpm() {
        this.bpmSlider = [];
        this.initBpmSlider();
        this.toggleValueChange();
      },

      onCloseDuration() {
        this.durationSlider = [];
        this.initDurationSlider();
      },

      getAllLabels(data) {
        if (Array.isArray(data) && data.length > 0) {
          return one(data);
        }

        return [];

        function one(data) {
          return data.reduce((prev, next) => {
            prev.push(next);

            if (next.child && next.child.length > 0) {
              prev = prev.concat(one(next.child));
            }

            return prev;
          }, [])
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter-labels {
    ::v-deep {
      .ant-tag {
        height: 32px;
        padding: 5px 12px;
        font-size: inherit;
      }

      .filter-footer {
        .anticon {
          color: inherit;
        }
      }
    }

    .filter-bottom {
      padding-top: 1px;
      padding-left: 1px;
    }

    .tag-button-class {
      border-color: $border-color;
      margin-top: -1px;
      margin-left: -1px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .filter-top {
      .ant-radio-button-wrapper {
        width: 48%;
        margin-right: 0 !important;

        &:nth-child(2n + 1) {
          margin-right: 4% !important;
        }
      }
    }

    .filter-middle {
      .filter-middle-item {
        display: block;
        margin-bottom: 24px;
        .label {
          width: 100%;
        }
      }
    }
  }
</style>
