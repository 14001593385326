<template>
  <div class="filter-music">
    <a-affix :offset-top="64">
      <div class="content-form pos-relative container" style="background-color: #fff;">
        <filter-labels
          class="visible-md hidden-sm"
          :defaultValue="defaultValue.label"
          :musicNumDir="musicNumDir"
          @change="onChangeFilterPc"
        ></filter-labels>

        <div class="visible-sm hidden-md m-bottom-md">
          <a-button
            class="w-100 filter-box-mobile"
            size="large"
            :type="isSearching ? 'common' : 'default'"
            @click="onSelectFilterLabelMobile"
          >
            <span>筛选</span>
            <icon-font type="iconfilter"></icon-font>
          </a-button>
        </div>

        <!-- <div class="sort-filter"> -->
        <a-form :form="form" layout="inline" class="sort-filter">
          <a-form-item>
            <a-radio-group
              size="large"
              button-style="solid"
              v-decorator="['sort_type', {initialValue: defaultValue.sort_type}]"
            >
              <a-radio-button
                v-for="item in FilterMusicOptions"
                :value="item.value"
                :key="item.value"
              >{{item.label}}</a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item class="m-empty">
            <a-select
              :options="SortOptionsNew"
              v-decorator="['sort', {initialValue: defaultValue.sort}]"
              class="sort-box"
              size="large"
            ></a-select>
          </a-form-item>
        </a-form>
        <!-- </div> -->

        <a-divider></a-divider>
      </div>
      <!-- <div class="content-form pos-relative container" style="background-color: #fff;"> -->
      <!-- </div> -->
    </a-affix>

    <ng-dialog :visible.sync="showFilterMobileDrawer" :bodyStyle="bodyStyle" @close="onCloseDrawer">
      <filter-labels
        class="visible-sm hidden-md"
        :default-value="mobileDefaultLabel"
        :musicNumDir="musicNumDir"
        @change="onChangeFilterMobile"
      ></filter-labels>

      <template v-slot:footer>
        <a-button class="filter-confirm w-100 h-40 ft16" type="primary" @click="onConfirmFilter">确定</a-button>
      </template>
    </ng-dialog>
  </div>
</template>

<script>
  import {Affix} from 'ant-design-vue';
  import FilterLabels from '@/pages/components/music/filter/FilterLabels.vue';
  import NgDialog from '@/components/NgDialog.vue'

  import {dialogMixins} from '@/mixins/index';
  import {FilterMusicOptions, SortOptions} from '@/config/options';

  export default {
    data() {
      return {
        FilterMusicOptions,

        initSearchLabel: {},

        showFilterMobileDrawer: false,

        SortOptionsNew: [{label: '默认排序', value: ''}].concat(SortOptions),

        labelsFilter: {},

        mobileDefaultLabel: {},

        musicNumDir: {}
      }
    },
    computed: {
      resultApi() {
        return this.isPlaylist ? this.$axios.Play_Music_List : this.$axios.Music_List
      },

      isSearching() {
        let {label_ids, bpm, duration} = this.labelsFilter;

        return label_ids && label_ids.length > 0 || bpm && bpm.length > 0 || duration && duration.length > 0;
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values, all) => {
          this.sortValues = all;

          let params = this.getSearchParams();

          this.$emit('labelsChange', params);

          this.$emit('change', params);

          this.setParamsUrl(params);
        }
      });
    },

    props: {
      /**
       * {
       *  label, // 放置标签
       * sort,
       * sort_type,
       * }
       */
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      musicNumParams: {
        type: Object,
        default() {
          return {}
        }
      }
    },

    components: {
      FilterLabels,
      NgDialog,
      AAffix: Affix
    },

    mixins: [dialogMixins],

    created() {
      this.sortValues = {};
    },

    mounted() {
      // this.setDefaultValue();
    },

    watch: {
      defaultValue: {
        handler(newVal) {
          if (!newVal || Object.keys(newVal).length === 0) return false

          let label = newVal.label;

          if (label) {
            this.labelsFilter = Object.assign({}, this.labelsFilter, label);
          }
        },
        immediate: true
      },

      musicNumParams: {
        handler(newVal) {
          this.getMusicNumUnderLabels(newVal || {});
        },
        immediate: true
      }
    },

    methods: {
      getMusicNumUnderLabels(params) {
        this.$axios.CommonMusicNumber(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.musicNumDir = data.data ? data.data.list : {};
          }
        })
      },

      onSelectFilterLabelMobile() {
        // this.labelDefaultValueMobile = Object.assign({}, this.labelsFilter);
        this.mobileDefaultLabel = Object.assign({}, this.labelsFilter);
        this.showFilterMobileDrawer = true;
      },

      getSearchParams(labelsFilter) {
        let {label_ids, bpm, duration} = labelsFilter ? labelsFilter : this.labelsFilter;
        let [bcmin, bcmax] = this.$config.MusicSearchBpmRange;
        let [dcmin, dcmax] = this.$config.MusicSearchDurationRange;

        let params = {label_ids: label_ids || []};

        if (Array.isArray(bpm)) {
          let [bmin, bmax] = bpm;

          params[`bpm[min]`] = typeof bmin !== 'undefined' && bmin > bcmin ? bmin : '';
          params[`bpm[max]`] = typeof bmax !== 'undefined' && bmax < bcmax ? bmax : '';
        }

        if (Array.isArray(duration)) {
          let [dmin, dmax] = duration;

          params[`duration[min]`] = typeof dmin !== 'undefined' && dmin > dcmin ? dmin : '';
          params[`duration[max]`] = typeof dmax !== 'undefined' && dmax < dcmax ? dmax : '';
        }

        params.sort = this.sortValues.sort || '';
        params.sort_type = this.sortValues.sort_type || '';

        return params;
      },

      onConfirmFilter() {
        this.labelsFilter = this.labelsFilterCopy;
        this.showFilterMobileDrawer = false;

        let params = this.getSearchParams();

        this.$emit('labelsChange', params);

        this.$emit('change', params);

        this.setParamsUrl(params);
      },

      onChangeFilterPc(value) {
        this.labelsFilter = Object.assign({}, value)

        let params = this.getSearchParams();

        this.$emit('labelsChange', params);

        this.$emit('change', params);

        this.setParamsUrl(params);
      },

      onChangeFilterMobile(value) {
        this.labelsFilterCopy = Object.assign({}, value)

        let params = this.getSearchParams(this.labelsFilterCopy);

        this.$emit('labelsChange', params);
      },

      onCloseDrawer() {
        this.$emit('update:visible', false)
      },

      setParamsUrl(params = {}) {
        let {label_ids = []} = params;

        this.$root.$goSearch({
          labels: label_ids.join(',')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-affix {
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: -10000px;
        right: -10000px;
        bottom: 0;
        background-color: #fff;
      }
    }
  }

  .filter-box-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-music {
  }

  @media screen and (max-width: $screen-width-md) {
    .sort-filter {
      display: flex;
      justify-content: space-between;
    }

    .sort-box {
      width: 12rem;
    }
  }

  // pc
  @media screen and (min-width: $screen-width-md) {
    .content-form {
      padding-top: 2.4rem;
    }

    .sort-filter {
      position: absolute;
      top: 2.4rem;
      right: 0;
    }

    .sort-box {
      width: 220px;
    }
  }
</style>
